import * as Phaser from 'phaser';
import { recoverData } from './preloader';
import { ModalWndMode } from './preloader';
import { myModalWnd } from './preloader';

export type domElContent = {
    htmlContent:string,
    styleContent:string
}

export class WinScene extends Phaser.Scene
{
    rbImg:Phaser.GameObjects.Image;
    emptyAnchorX:number;
    constructor ()
    {
        super('winScene');
    }  

    init(data){
        if("x" in data)
        this.emptyAnchorX=data.x;
    }

    create ()
    {
        // globalThis.currentScene = this;
        // this.rbImg = this.add.image(600,350,"atlas0", "moneyPiece");

        // this.add.tween({
        //     targets:this.rbImg,
        //     props:{y:0},
        //     duration:500,
        //     yoyo:true
        // })

        const emitter = this.add.particles(600, 100, 'atlas0', {
            frame: [ 'goldEl' ],
            speed: 300,
            gravityY: 400,
            lifespan: 4000,
            scale: 0.3,
            duration:5000,
            
            blendMode: 'ADD',
        });

        emitter.on('complete', () => {
            try{
                globalThis.gYsdk.features.GameplayAPI.stop()
            }catch(err){}
            let content:domElContent = myModalWnd.getModalWnd(ModalWndMode.WIN);
            let dom = this.add.dom(600, 300,'div', content.styleContent);
            dom.setHTML(content.htmlContent);
            dom.addListener('click');
            dom.on('click', (evt) => {
                let buttonsCont = document.getElementById("buttonsCont");
                let currAchievments:Achievments ={ 
                    numAttempts:globalThis.plrAchievments.numAttempts++,
                    numKilledGangs: globalThis.numKilledGangs
                }
            
                if(buttonsCont.contains(evt.target))
                {
                    dom.removeAllListeners('click');
                    recoverData(currAchievments,"sceneB");
                    //this.scene.start("sceneB");
                }
            });
            // globalThis.currentScene.scene.stop();
            // recoverData({numKilledGangs:0, numAttempts:0}, "sceneB")
            // this.scene.start("sceneB");
        });
    }

    update(time: number, delta: number): void {
        //console.log("dom");
    }
}